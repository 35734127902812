import { cleanProjectData, getProjectData } from '@mpx-sdk/helpers/assets';
import { userAtom, userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import AssetDisplay from '@mpx-sdk/ui/components/assets/AssetDisplay';
import { useAtomValue } from 'jotai';
import { debounce, isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

export default function Gallery(): ReactElement | null {
	const [projectData, setProjectData] = useState<PublicAsset[]>([]);
	const currentUser = useAtomValue(userAtom);
	const slugUser = useAtomValue(userProfileDisplayAtom);

	async function getProjectDataFromUser() {
		if (slugUser?.id) {
			const options = {
				maxResults: 25,
				paginatingIndex: projectData.length,
				user: slugUser?.username ?? slugUser?.id,
			};

			const data = await getProjectData(options, currentUser?.id);

			if (data) {
				const cleanedData = cleanProjectData([...projectData, ...data]);

				setProjectData(cleanedData);
			}
		}
	}

	// Debounce the function to prevent multiple calls
	const debouncedGetProjectDataFromUser = debounce(getProjectDataFromUser, 1000);

	useEffect(() => {
		debouncedGetProjectDataFromUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugUser]);

	return !isEmpty(projectData) ? (
		<AssetDisplay
			centerTitle
			displaySort={false}
			horizontalScroll
			onScrollEnd={() => {
				getProjectDataFromUser();
			}}
			projectData={projectData}
			title='Gallery'
		/>
	) : null;
}
