import { generateThemeColor } from '@mpx-sdk/helpers/theme';
import { cleanDefaultCategoryFromURLQuery } from '@mpx-sdk/helpers/url';
import { PhaseIcon } from '@mpx-sdk/images';
import { libraryActiveCategories, pageThemeAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs';
import { darkTheme } from '@mpx-sdk/ui/themes';
import { Switch, Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { getCookie } from 'cookies-next';
import { useAtom, useSetAtom } from 'jotai';
import { isEmpty, isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface PhaseDependentSwitchProps {
	/** Custom function for phase dependent switch */
	onClick?: () => void;
}

/**
 * Create a phase dependent button
 * @deprecated This component is deprecated and will be removed in the future. Currently in discussion by UX team about the future of this component.
 * */
export default function PhaseDependentSwitch({ onClick }: PhaseDependentSwitchProps): ReactElement | null {
	const router = useRouter();
	const { c: queryCategory } = router.query;

	const [activeCategory, setActiveCategory] = useAtom(libraryActiveCategories);
	const setColorTheme = useSetAtom(pageThemeAtom);

	/** The "forceState" cookie if exists */
	const cookies = getCookie(CookieNames.FORCE_STATE) ? JSON.parse(getCookie(CookieNames.FORCE_STATE) as string) : {};

	const cookieCategory = cookies.c || '';
	const queryCategoryAsString = Array.isArray(queryCategory) ? queryCategory.toString() : queryCategory || '';

	/** Human readable category */
	const [phase, setPhase] = useState(cookieCategory);

	const [checked, setChecked] = useState(
		isEqual(cookieCategory?.split(',').sort(), queryCategoryAsString?.split(',').sort()),
	);

	useEffect(() => {
		if (cookieCategory) {
			const newCategory = checked ? cookieCategory.split(',') : ['animation', 'rig', 'model', 'material'];
			setColorTheme(createTheme(deepmerge(darkTheme, generateThemeColor({ c: newCategory.toString() }))));
			setActiveCategory(newCategory);
		}
	}, [checked, setActiveCategory, setColorTheme, cookieCategory]);

	useEffect(() => {
		cleanDefaultCategoryFromURLQuery(activeCategory);
	}, [activeCategory]);

	useEffect(() => {
		// If cookieCategory and queryCategory are the same, then set checked to true
		if (cookieCategory && queryCategory && cookieCategory === queryCategory) {
			setChecked(true);
		}

		// Make the cookieCategory human readable
		let humanReadableCategory = cookieCategory.split(',');
		// If only one category, do "[0]", if more than one, add an "and" between the last two
		humanReadableCategory =
			humanReadableCategory.length > 1
				? `${humanReadableCategory.slice(0, -1).join(', ')} and ${humanReadableCategory.slice(-1)}`
				: humanReadableCategory[0];
		setPhase(humanReadableCategory);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Only display phase dependent switch is cookie?.c exist and router path includes /library
	return !isEmpty(cookieCategory) && router.pathname.includes('/library') ? (
		<Tooltip title={checked ? `Switch to ${phase} phase` : `Switch out of ${phase} phase`}>
			<Switch
				checked={checked}
				checkedIcon={
					<PhaseIcon
						className='phase-dependent-button'
						sx={{
							color: '#000',
							backgroundColor: checked ? 'primary.main' : '#5C5E62',
						}}
					/>
				}
				className='phase-dependent-switch'
				icon={
					<PhaseIcon
						className='phase-dependent-button'
						sx={{
							color: '#fff',
							backgroundColor: checked ? 'primary.main' : '#5C5E62',
						}}
					/>
				}
				onChange={() => {
					setChecked(!checked);
					onClick?.();
				}}
			/>
		</Tooltip>
	) : null;
}
