import { ClickAwayListener, Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface AssetCardExportTooltipProps extends TooltipProps {
	/** Function to be called when the tooltip is closed */
	onClose: () => void;
}

/**
 * A styled Tooltip component that includes a ClickAwayListener.
 * @returns The AssetCardExportTooltip component
 */
const AssetCardExportTooltip = styled(({ onClose, open, className, ...props }: AssetCardExportTooltipProps) =>
	open ? (
		<ClickAwayListener onClickAway={onClose}>
			<Tooltip {...props} classes={{ popper: className }} />
		</ClickAwayListener>
	) : (
		props?.children
	),
)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		alignItems: 'flex-start',
		backgroundColor: 'primary.main',
		borderRadius: '0px 40px 40px 40px',
		color: 'primary.contrastText',
		display: 'flex',
		fontSize: theme.typography.pxToRem(20),
		marginLeft: '14%',
		marginTop: '-15px !important',
		maxWidth: '75%',
		padding: '15px',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: 'primary.main',
	},
}));

export default AssetCardExportTooltip;
