import { defaultSearchConfig, searchClient } from '@mpx-sdk/shared/configs/algolia';
import { SearchParamKeys } from '@mpx-sdk/types';
import ContentRefinement from '@mpx-sdk/ui/components/algolia/ContentRefinement';
import InfiniteHits from '@mpx-sdk/ui/components/algolia/InfiniteHits';
import NoResultsBoundary from '@mpx-sdk/ui/components/algolia/NoResultsBoundary';
import NoResults from '@mpx-sdk/ui/components/algolia/NoSearchResults';
import Middleware from '@mpx-sdk/ui/components/algolia/SearchMiddleware';
import AlgoliaSearchBar from '@mpx-sdk/ui/components/search-bar/AlgoliaSearchBar';
import { isArray, map } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

interface AlgoliaDisplayProps {
	/** Whether to display certain components of Algolia or not */
	display?: {
		/** Whether to display the search bar [true, default] or not [false] */
		searchBar?: boolean;
		/** Whether to display the content refinement [true, default] or not [false] */
		refinement?: boolean;
	};
	/** JSX component where the hits will be displayed in */
	assetDisplayJSX?: React.ReactElement;
	/** Custom search settings to override the default search settings */
	customSearchSettings?: any;
	/** The ID of the element to scroll to when the user clicks on a search result */
	infiniteScrollTarget?: string;
}

export default function AlgoliaDisplay({
	display = {
		searchBar: true,
		refinement: true,
	},
	assetDisplayJSX,
	customSearchSettings,
	infiniteScrollTarget,
}: AlgoliaDisplayProps): ReactElement {
	const router = useRouter();
	const { query } = router;

	const searchSettings = {
		...defaultSearchConfig(),
		...customSearchSettings,
	};

	useEffect(() => {
		if (query) {
			const mappings = {
				category: SearchParamKeys.Category,
				query: SearchParamKeys.Query,
				tags: SearchParamKeys.Tags,
			};

			map(mappings, (value, key) => {
				if (query[value]) {
					if (isArray(query[value])) {
						searchSettings[key] = query?.[value];
					} else {
						searchSettings[key] = (query[value] as string)?.split?.(',');
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<InstantSearch
			indexName={searchSettings.indexName}
			initialUiState={{
				projects: {
					refinementList: {
						category: searchSettings.category ?? [],
						facet: searchSettings.facet ?? [],
						facetFilters: searchSettings.facetFilters ?? [],
						tags: searchSettings.tags ?? [],
					},
					query: searchSettings.query,
				},
			}}
			insights
			searchClient={searchClient}
		>
			<Configure
				analytics
				facetFilters={searchSettings.facetFilters ?? []}
				hitsPerPage={searchSettings.hitsPerPage}
				ruleContexts={['library']}
			/>

			{display.searchBar && <AlgoliaSearchBar searchSettings={searchSettings} />}

			<Middleware />

			<NoResultsBoundary fallback={<NoResults />}>
				{display.refinement && <ContentRefinement searchSettings={searchSettings} />}

				<InfiniteHits assetDisplayJSX={assetDisplayJSX} infiniteScrollTarget={infiniteScrollTarget} />
			</NoResultsBoundary>
		</InstantSearch>
	);
}
