import { request } from '../api';

export async function getUser(username: string): Promise<MPS.User | null> {
	return request(`/users/${username}`, 'get')
		.then((data) => data?.data)
		.catch((err) => {
			console.error(err);
			return null;
		});
}
