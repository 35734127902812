// Using Material-UI and Algolia components, create a button that says "My Uploads" and when clicked, will refine the search to include only results that have the username of the current user.
import { userAtom } from '@mpx-sdk/shared/atoms';
import { Checkbox, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

// eslint-disable-next-line react/prop-types
function MyUploads(props) {
	const [checked, setChecked] = useState(false);
	const user = useAtomValue(userAtom);
	const { refine, items } = useRefinementList(props);

	return user?.username ? (
		<Stack
			alignItems='center'
			direction='row'
			onClick={() => {
				setChecked(!checked);

				if (checked && !isEmpty(items)) {
					refine('');
				} else if (user && !checked) {
					refine(user.username as string);
				}
			}}
			spacing={0}
		>
			<Checkbox checked={checked} />

			<Typography>My Uploads</Typography>
		</Stack>
	) : null;
}

export default MyUploads;
