/* eslint-disable no-nested-ternary */
import AssetSort from '@mpx-sdk/ui/components/assets/sort/AssetSort';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface AssetDisplayHeaderProps {
	/** An optional title to display above the asset grid */
	title?: string;
	/** Whether or not to center the title */
	centerTitle?: boolean;

	/** The default sort to use */
	defaultSort?: string;
	/** The options to display in the dropdown menu [Optional] */
	sortOptions?: string[];
	/** Whether or not to display a sorting option */
	displaySort?: boolean;

	/** If giving a custom sort element, add here. This will override the displaySort element if set to true */
	customCornerElement?: ReactElement;

	/** Whether a "See More" link is below the grid */
	subtextLink?: string;
}

export default function AssetDisplayHeader({
	centerTitle = false,
	customCornerElement,
	defaultSort,
	displaySort = false,
	subtextLink,
	sortOptions,
	title,
}: AssetDisplayHeaderProps): ReactElement | null {
	return title || displaySort || customCornerElement ? (
		<Grid
			alignItems='center'
			className='asset-subsection-stack-header'
			container
			direction='row'
			justifyContent='space-between'
			spacing={1}
			sx={{
				position: 'relative',
			}}
		>
			{title && centerTitle && (customCornerElement || displaySort) ? (
				// Empty grid
				<Grid item sm={3} xs={customCornerElement ? 1 : 3} />
			) : null}

			{title && (
				<Grid
					className={`asset-subsection-title  ${centerTitle ? 'asset-subsection-title-center' : ''}`}
					item
					sm={displaySort || customCornerElement ? 6 : 12}
					sx={{
						display: 'flex',
						justifyContent: centerTitle || !(customCornerElement || displaySort) ? 'center' : 'flex-start',
						marginLeft: centerTitle ? 'auto !important' : '0 !important',
					}}
					xs={customCornerElement ? 10 : displaySort ? 6 : 12}
				>
					<LinkWrapper href={subtextLink ?? ''}>
						<Typography variant='h4'>{title}</Typography>
					</LinkWrapper>
				</Grid>
			)}

			{(displaySort || customCornerElement) && (
				<Grid
					className='asset-subsection-sort-container'
					item
					sm={title ? 3 : 12}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
					xs={customCornerElement ? 1 : 12}
				>
					{customCornerElement ?? <AssetSort defaultSort={defaultSort} sortOptions={sortOptions} />}
				</Grid>
			)}
		</Grid>
	) : null;
}
