import { filterIgnoreProjects } from '@mpx-sdk/helpers/assets';
import { adminFeaturesAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import AssetDisplayHeader from '@mpx-sdk/ui/components/assets/AssetDisplayHeader';
import AssetGrid from '@mpx-sdk/ui/components/assets/grid/AssetGrid';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { type ReactElement, useEffect, useState } from 'react';

interface AssetDisplayProps {
	/** List of assets/projects to display */
	projectData: PublicAsset[];

	// Props related to the title
	/** An optional title to display above the asset grid */
	title?: string;
	/** Whether or not to center the title */
	centerTitle?: boolean;

	// Props related to the sort
	/** Whether or not to display a sorting option */
	displaySort?: boolean;
	/** The options to display in the dropdown menu [Optional] */
	sortOptions?: string[];
	/** The default sort to use */
	defaultSort?: string;
	/** If giving a custom sort element, add here */
	customSortElement?: ReactElement;

	// Props related to asset card
	/** Whether you want to hide the asset card's buttons */
	hideMediaOverlay?: boolean;
	/** Whether the delete button should appear on the asset card */
	deleteAsset?: boolean;
	/** The function to call when a project is clicked */
	onClick?: (...args: any[]) => void;

	// Props related to the appearance of the asset card or the asset grid
	/** Whether the grid should have rounded corners or not */
	roundedCorners?: boolean;
	/** The maximum number of grid rows to display */
	maxNumberOfRows?: number;
	/** The class name to give the grid */
	className?: string;

	// Props related to subtext
	/** The sub text to display below the title */
	subText?: string | ReactElement;
	/** If you want a sub text link, add the link here */
	subtextLink?: string;

	// Props related to the horizontal scroll
	/** Whether the asset grid should be displayed horizontally [default is false] */
	horizontalScroll?: boolean;

	// Props related to giving asset display certain behavior
	/** The behavior to give the asset grid */
	displayBehavior?: 'default' | 'trending' | 'smallerScreen';

	// Props related to loading more data
	/** The function to call when the user scrolls to the end of the grid */
	onScrollEnd?: () => void;
	/** Whether or not to show assets that are filtered/hidden */
	showFilteredAssets?: boolean;
}

/** Display project data and their respective asset cards */
export default function AssetDisplay({
	centerTitle = false,
	className,
	customSortElement,
	defaultSort,
	deleteAsset = false,
	displayBehavior = 'default',
	displaySort = true,
	hideMediaOverlay = false,
	horizontalScroll = false,
	maxNumberOfRows,
	onClick,
	onScrollEnd,
	projectData,
	roundedCorners = false,
	showFilteredAssets = false,
	sortOptions,
	subText,
	subtextLink,
	title,
}: AssetDisplayProps): ReactElement | null {
	const [filteredProjectData, setFilteredProjectData] = useState<PublicAsset[]>([]);
	const adminFeatures = useAtomValue(adminFeaturesAtom);

	useEffect(() => {
		if (!showFilteredAssets) {
			setFilteredProjectData(filterIgnoreProjects(projectData));
		} else {
			setFilteredProjectData(projectData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData, showFilteredAssets, adminFeatures]);

	return (
		<div className={`asset-subsection-stack ${className}`}>
			{!isEmpty(filteredProjectData) && (
				<>
					{/* Title row including header and sort, if want to include */}
					<AssetDisplayHeader
						centerTitle={centerTitle}
						customCornerElement={customSortElement}
						defaultSort={defaultSort}
						displaySort={displaySort}
						sortOptions={sortOptions}
						subtextLink={subtextLink}
						title={title}
					/>

					<AssetGrid
						deleteAsset={deleteAsset}
						displayBehavior={displayBehavior}
						hideMediaOverlay={hideMediaOverlay}
						horizontalScroll={horizontalScroll}
						maxNumberOfRows={maxNumberOfRows}
						onClick={onClick}
						onScrollEnd={onScrollEnd}
						projectData={filteredProjectData}
						roundedCorners={roundedCorners}
						subtextBelow={Boolean(subText)}
					/>

					{subText ? (
						<LinkWrapper href={subtextLink ?? ''}>
							{typeof subText === 'string' ? (
								<Button
									style={{
										color: 'text.primary',
										fontWeight: 'normal',
										padding: 0,
										textAlign: 'center',
										textDecoration: 'underline',
										textTransform: 'inherit',
									}}
									variant='text'
								>
									{subText}
								</Button>
							) : (
								subText
							)}
						</LinkWrapper>
					) : null}
				</>
			)}
		</div>
	);
}
