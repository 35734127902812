import { defaultSearchConfig } from '@mpx-sdk/shared/configs/algolia';
import { SearchParamKeys } from '@mpx-sdk/types';
import { map } from 'lodash';
import React from 'react';
import { useInstantSearch } from 'react-instantsearch';

function middleware() {
	return {
		// This is called when the search state changes, and we can use it to update the url params
		// It doesn't affect anything to do with the search, it's just for the url
		onStateChange({ uiState }) {
			const { query, refinementList } = uiState[defaultSearchConfig().indexName];

			const newQueryParams = new URLSearchParams(window.location.search);

			const mappings = {
				[SearchParamKeys.Query]: query,
				[SearchParamKeys.Category]:
					refinementList?.category?.length === 4 ? null : refinementList?.category?.join(','),
				[SearchParamKeys.Tags]: refinementList?.tags?.join(','),
				[SearchParamKeys.Username]: refinementList?.['user.username'],
			};

			// This will add take the current url params, add the new ones, and remove the ones that are null
			map(mappings, (value, key) => {
				if (value) {
					newQueryParams.set(key, value);
				} else {
					newQueryParams.delete(key);
				}
			});

			if (typeof window !== 'undefined' && window.history) {
				window.history.replaceState({}, '', `?${newQueryParams.toString()}`);
			}
		},
	};
}

export default function Middleware() {
	const { addMiddlewares } = useInstantSearch();

	React.useLayoutEffect(() => addMiddlewares(middleware));

	return null;
}
