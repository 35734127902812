import { defaultThumbnail } from '@mpx-sdk/images';
import { assetGridBreakpoints, maxWidthSizeValue } from '@mpx-sdk/shared/configs';
import { Fade } from '@mui/material';
import Image from 'next/image';
import { ReactElement, useState } from 'react';

interface AssetThumbnailProps {
	/** The alt text to display for the thumbnail */
	alt?: string;
	/** The thumbnail URL to display */
	src?: string;
}

/** Asset Thumbnail component. */
export default function AssetThumbnail({ alt, src }: AssetThumbnailProps): ReactElement {
	const [loaded, setLoaded] = useState(false);

	return (
		<>
			<Fade in={loaded}>
				<Image
					alt={alt ?? 'Asset Thumbnail'}
					fill
					loading='lazy'
					onLoad={() => {
						setLoaded(true);
					}}
					sizes={`
						(max-width: 600px) (${assetGridBreakpoints.xs}/12 * ${maxWidthSizeValue})vw,
						(max-width: 1200px) (${assetGridBreakpoints.sm}/12 * ${maxWidthSizeValue})vw,
						(max-width: 1536px) (${assetGridBreakpoints.lg}/12 * ${maxWidthSizeValue})vw,
						(${assetGridBreakpoints.xl}/12 * ${maxWidthSizeValue})vw,
					`}
					src={src ?? defaultThumbnail}
					unoptimized
				/>
			</Fade>
			<Fade in={!loaded} unmountOnExit>
				<Image alt={alt ?? 'Asset Thumbnail'} fill loading='lazy' src={defaultThumbnail} unoptimized />
			</Fade>
		</>
	);
}
