/* eslint-disable no-useless-escape */
import { matureWordsFilter } from '../input';

/** Valid username character pattern */
export const userNamePattern = /^[a-zA-Z0-9._\-]+$/;
/** Valid display name character pattern */
export const displayNamePattern = /^[a-zA-Z0-9._\- ]+$/;

// Helper function to validate the username format using the provided regex pattern
/**
 * Check if the username is invalid
 * @returns {Boolean} Whether the username is invalid [true] or not [false]
 */
export function isUsernameInvalid(
	/** The username you want tested */
	username: string,
	/** The pattern you want to test the username/string against */
	whichPattern?: 'username' | 'displayName' | RegExp,
): boolean {
	let pattern = userNamePattern;
	if (whichPattern === 'displayName') {
		pattern = displayNamePattern;
	}

	/** Check if the username contains any invalid special characters */
	const invalidCharacters = !pattern.test(username);

	/** Check for inappropriate words */
	const badWordCheck = matureWordsFilter(username);

	// Check if the username starts or ends with an underscore
	const startsWithUnderscore = username.startsWith('_');
	const endsWithUnderscore = username.endsWith('_');

	return invalidCharacters || startsWithUnderscore || endsWithUnderscore || badWordCheck?.includes('*');
}
