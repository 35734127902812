/* eslint-disable no-nested-ternary */
import { updateUserAvatar } from '@mpx-sdk/helpers/users';
import { Edit2Icon } from '@mpx-sdk/images';
import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import { fileToBase64 } from '@mpx-sdk/shared/utils';
import { Avatar, Badge, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useAtom } from 'jotai';
import jsonToFormData from 'json-form-data';
import { ReactElement, useEffect, useState } from 'react';

interface EditAvatarProps {
	displayButton?: boolean;
	displayBadge?: boolean;
	sx?: any;
}

export default function EditAvatar({
	displayButton = true,
	displayBadge = false,
	sx,
}: EditAvatarProps): ReactElement | null {
	const [slugUser, setSlugUser] = useAtom(userProfileDisplayAtom);
	const [avatar, setAvatar] = useState<any>(slugUser?.photoUrl ?? null);
	const [saving, setSaving] = useState<boolean>(false);

	async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const input = event.target;
		if (input.files && input.files[0]) {
			fileToBase64(input.files[0], setAvatar);

			setSaving(true);
		}
	}

	async function handleSaving() {
		if (avatar && saving) {
			setSaving(false);

			const formData = jsonToFormData(
				{ photoUrl: avatar?.actual_file },
				{
					initialFormData: new FormData(),
					mapping(value) {
						return value;
					},
				},
			);

			const userData = await updateUserAvatar(slugUser, formData);

			setSlugUser(userData);
		}
	}

	function handleUploadButtonClick() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.addEventListener('change', (event) => {
			handleFileChange(event as any);

			// Remove the input from the DOM
			input.parentNode?.removeChild(input);
		});
		input.click();
	}

	useEffect(() => {
		if (avatar && saving) {
			handleSaving();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [avatar, saving]);

	useEffect(() => {
		setAvatar(slugUser?.photoUrl);
	}, [slugUser]);

	return slugUser ? (
		<Tooltip aria-live='polite' arrow describeChild title='Upload a new avatar'>
			<Stack alignItems='center' direction='row' justifyContent='center' spacing={2}>
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					badgeContent={
						displayBadge && (
							<IconButton
								sx={{
									backgroundColor: 'white',
									borderRadius: '50%',
									boxShadow: 2,
									color: 'icons.edit',
									'&:hover': {
										backgroundColor: 'primary.light',
										color: 'primary.contrastText',
									},
								}}
							>
								<Edit2Icon />
							</IconButton>
						)
					}
					onClick={() => {
						handleUploadButtonClick();
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							handleUploadButtonClick();
						}
					}}
					overlap='circular'
				>
					<Avatar
						alt={slugUser.displayName}
						aria-label='Upload a new avatar'
						role='button'
						src={slugUser.photoUrl}
						sx={{
							cursor: 'pointer',
							height: '80%',
							margin: 'auto',
							maxHeight: 80,
							maxWidth: 80,
							position: 'relative',
							width: '80%',
							...sx,
						}}
						tabIndex={0}
					/>
				</Badge>

				{displayButton && (
					<Button
						onClick={() => {
							handleUploadButtonClick();
						}}
						title='Upload a new avatar'
						variant='contained'
					>
						Upload
					</Button>
				)}
			</Stack>
		</Tooltip>
	) : null;
}
