import { newLibraryState } from '@mpx-sdk/helpers/library';
import { inAppBrowserAtom, libraryActiveCategories, libraryURLParamsAtom } from '@mpx-sdk/shared/atoms';
import { CheckCircle as CheckCircleIcon, RadioButtonUnchecked as RadioButtonUncheckedIcon } from '@mui/icons-material';
import { Checkbox, Grid } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { capitalize, isEqual } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface FilterCategoriesProps {
	/** Category options allowed */
	categoryOptions?: string[];

	changeFunction?: (newState: string[], type: 'categories' | 'sort') => void;
}

/** Sort library projects by categories */
export default function FilterCategories({
	categoryOptions = ['model', 'material', 'rig', 'animation'],
	changeFunction,
}: FilterCategoriesProps): ReactElement | null {
	const router = useRouter();
	const { query } = router;
	const [libraryURLParams, setLibraryURLParams] = useAtom(libraryURLParamsAtom);

	/** Which categories to actively sort by */
	const [categories, setCategories] = useAtom(libraryActiveCategories);

	const inApp = useAtomValue(inAppBrowserAtom);

	const defaultCategories = inApp ? ['model'] : categoryOptions;

	const [allCategoriesSelected, setAllCategoriesSelected] = useState(
		isEqual([...categories].sort(), [...defaultCategories].sort()) || false,
	);

	const [displayFilter, setDisplayFilter] = useState(true);

	function updateCategory(newCategories: string[]) {
		if (!isEqual([...newCategories].sort(), [...categories].sort())) {
			// If given an update function, call it with new categories
			changeFunction?.(newCategories, 'categories');

			// Check if all projects are checked, if not, then add them to URL
			if (!isEqual([...newCategories].sort(), [...defaultCategories].sort()) && categoryOptions.length > 0) {
				const urlState = newCategories.join('%2C');
				setLibraryURLParams(
					newLibraryState({
						c: urlState,
					}),
				);
			} else {
				setLibraryURLParams(
					newLibraryState({
						c: '',
					}),
				);
			}

			// Update the state
			setCategories(newCategories);
		}
	}

	/**
	 * Handle updating checkbox state
	 * @param {String} category New category to add/remove
	 * @example <caption>This handle function updates which checkboxes are checked and updates the library accordingly</caption>
	 * handleCheckboxChange('rig')
	 * // returns null (If unchecked, now checks rig checkbox while filtering library projects by adding those projects who have a rig category)
	 */
	function handleCheckboxChange(category: string) {
		/** List of categories being displayed */
		let newCategories: string[] = [];

		if (inApp) {
			newCategories = [category];
		} else {
			newCategories = [...categories];

			/** Index position of the category being added/removed */
			const index: number = newCategories.indexOf(category);

			if (index > -1) {
				// If exist, remove the category
				newCategories.splice(index, 1);
			} else {
				// Add the category
				newCategories.push(category);
			}
		}

		updateCategory(newCategories);
	}

	function shouldDisplayFilter() {
		if ((query?.f?.toString() === 'true' && query?.c) || inApp || categoryOptions?.length < 1) {
			setDisplayFilter(false);
		} else {
			setDisplayFilter(true);
		}
	}

	useEffect(() => {
		if (isEqual([...categories].sort(), [...categoryOptions].sort())) {
			setAllCategoriesSelected(true);
		} else {
			setAllCategoriesSelected(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories]);

	useEffect(() => {
		shouldDisplayFilter();

		// If categories exist in URL param, set them as active categories
		if (libraryURLParams?.c) {
			// URL will have the list of categories separated by commas (%2C), so we need to split it
			/** Categories passed via URL */
			let categoriesQuery = libraryURLParams.c;
			if (typeof categoriesQuery === 'string') {
				categoriesQuery = categoriesQuery.trim().split('%2C');
			}
			if (categoriesQuery.length === 1) {
				categoriesQuery = categoriesQuery[0].split(',');
			}

			// Trim all categories and remove empty ones
			categoriesQuery.forEach((category) => category.trim().toLowerCase());
			categoriesQuery.filter((category) => category.length > 0 && !categoryOptions.includes(category));

			setCategories(categoriesQuery);

			// If given an update function, call it with new categories
			changeFunction?.(categoriesQuery, 'categories');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return displayFilter ? (
		<Grid alignItems='flex-start' container direction='row' justifyContent='center' spacing={1}>
			{/* If category options is more than 1, have an all category option */}
			{categoryOptions.length > 2 && (
				<Grid
					key='all-categories'
					className='search-checkbox-container'
					item
					sx={{
						'&.Mui-checked': {
							color: 'primary.main!important',
							svg: {
								color: 'primary.main!important',
							},
						},
					}}
				>
					<Checkbox
						checked={allCategoriesSelected}
						checkedIcon={<CheckCircleIcon />}
						icon={<RadioButtonUncheckedIcon />}
						inputProps={{
							'aria-label': `Toggle having all categories in library view`,
						}}
						onChange={() => updateCategory(categoryOptions)}
					/>
					<br />
					All categories
				</Grid>
			)}

			{categoryOptions.map((category) => (
				// Each grid item will be the category, with a circular checkbox to select/unselect the category, and the category title/name underneath it
				<Grid key={category} className='search-checkbox-container' item>
					<Checkbox
						checked={categories.includes(category)}
						checkedIcon={<CheckCircleIcon />}
						icon={<RadioButtonUncheckedIcon />}
						inputProps={{
							'aria-label': `Toggle having ${category} in library view`,
						}}
						onChange={() => handleCheckboxChange(category)}
					/>
					<br />
					{capitalize(category)}
				</Grid>
			))}
		</Grid>
	) : null;
}
