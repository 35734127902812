/* eslint-disable react/prop-types */
// Create sort by with algolia component with MUI
import { MenuItem, Select } from '@mui/material';
import { ReactElement } from 'react';
import { useSortBy } from 'react-instantsearch';

export default function SortBy(props): ReactElement {
	const { refine, currentRefinement, options } = useSortBy(props);

	return (
		<Select
			aria-label='Sort By'
			className='asset-sort-select'
			MenuProps={{
				sx: {
					marginTop: '10px',
				},
			}}
			onChange={(e) => {
				refine(e.target.value);
			}}
			SelectDisplayProps={{
				style: {
					paddingTop: 10,
					paddingBottom: 10,
				},
			}}
			sx={{
				color: 'text.primary',
				bgcolor: 'background.paper',
				borderRadius: 28,
				minWidth: '100%',
				maxWidth: '100%',
				svg: {
					color: 'primary.main',
				},
			}}
			value={currentRefinement}
		>
			{options.map((item) => (
				<MenuItem
					key={item.value}
					data-sort-option={item.value}
					data-test-category='algolia-sort-refinement'
					value={item.value}
				>
					{item.label}
				</MenuItem>
			))}
		</Select>
	);
}
