import { BackIcon, EditIcon, ShareIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom, openShareDialogAtom, userAtom, userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface HeaderProps {
	onClose?: () => void;
}

export default function Header({ onClose }: HeaderProps): ReactElement | null {
	const [backUrl, setBackUrl] = useState<string>('/');
	/** If last page in history is a library page (indicated by /library in url), then show back button */
	const [showBackButton, setShowBackButton] = useState<boolean>(false);
	const [slugUser, setSlugUser] = useAtom(userProfileDisplayAtom);
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const router = useRouter();
	const setOpenSocialSharing = useSetAtom(openShareDialogAtom);

	function generateIconButtons(): ReactElement | null {
		if (slugUser) {
			const iconOptions: IconButtonOptions = {
				share: {
					'aria-label': 'Share',
					icon: (
						<ShareIcon
							sx={{
								color: 'icons.inactive',
							}}
						/>
					),
					title: `Share ${
						slugUser?.username?.toLowerCase() === currentUser?.username?.toLowerCase()
							? 'your'
							: slugUser?.username || 'this'
					} profile`,
					onClick: () => setOpenSocialSharing(true),
					hide: inApp,
				},
				edit: {
					'aria-label': 'Edit',
					icon: (
						<EditIcon
							sx={{
								color: 'icons.inactive',
							}}
						/>
					),
					title: `Edit your profile data`,
					onClick: () => {
						router.push(`/profile`);
					},
					hide: slugUser?.username?.toLowerCase() !== currentUser?.username?.toLowerCase(),
				},
			};

			return (
				<>
					{Object.values(iconOptions).map((icon) => {
						if (!icon.hide) {
							return (
								<Tooltip key={icon.title} title={icon.title}>
									<IconButton
										aria-label={icon['aria-label']}
										className='sav-header-icon-button'
										onClick={icon.onClick}
									>
										{icon.icon}
									</IconButton>
								</Tooltip>
							);
						}

						return null;
					})}
				</>
			);
		}

		return null;
	}

	// Handle functionality of component
	function handleBackButton() {
		setSlugUser(null);

		onClose?.();
	}

	useEffect(() => {
		if (typeof window !== 'undefined' && window.history?.length > 1) {
			setShowBackButton(true);

			const previousUrl = document.referrer;
			if (previousUrl.startsWith(window.location.origin) && previousUrl !== window.location.href) {
				setBackUrl(previousUrl);
			}
		}
	}, []);

	return slugUser ? (
		<Stack alignItems='center' direction='row' justifyContent='space-between'>
			{/* Title */}
			<Stack alignItems='center' className='sav-header-title-container' direction='row'>
				{showBackButton && (
					<LinkWrapper href={backUrl} openNewTab={false} preventNewTab>
						<Tooltip arrow describeChild title='Back to previous page'>
							<IconButton onClick={() => handleBackButton()}>
								<BackIcon
									sx={{
										color: 'icons.inactive',
									}}
								/>
							</IconButton>
						</Tooltip>
					</LinkWrapper>
				)}

				<Typography className='sav-header-title'>
					{slugUser?.displayName ?? slugUser?.username ?? slugUser?.id ?? 'User'}
				</Typography>
			</Stack>

			<div>{generateIconButtons()}</div>
		</Stack>
	) : null;
}

type IconButtonOption = {
	'aria-label': string;
	hide?: boolean;
	icon: ReactElement;
	onClick: () => void;
	title: string;
};

type IconButtonOptions = {
	edit: IconButtonOption;
	share: IconButtonOption;
};
