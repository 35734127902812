import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CookieNames } from '@mpx-sdk/shared/configs';
import { ThemeOptions } from '@mui/material';
import { getCookie } from 'cookies-next';
import { isEmpty, isEqual } from 'lodash';
import { processLibraryURL } from './library';

/**
 * Generate theme colour based on the category of the library
 * @returns {Object} Theme options for MUI
 */
export function generateThemeColor(
	/** URL parameters, specifically the category (c) parameter. Optional, if not provided, will be generated from the URL */
	urlParams?: any,
): ThemeOptions {
	const inApp = UIHelper.isInAppBrowser();
	/** MUI theme options */
	const colorPallets = {
		primary: {
			light: 'inherit',
			main: 'inherit',
		},
		animation: {
			light: '#FFF0A2',
			main: '#FFE456',
		},
		material: {
			light: '#DDDDDD',
			main: '#BEBEBE',
		},
		mesh: {
			light: '#E1D7C9',
			main: '#B59C78',
		},
		rig: {
			light: '#FFA8D2',
			main: '#FF4EA3',
		},
		skin: {
			light: '#FFC8A0',
			main: '#FF9B53',
		},
	};
	const categoryOptions = Object.keys(colorPallets);

	/** Phase dependent category */
	let category = '';
	/** MUI color pallet based on category, if not default to primary (inherit) */
	let color = colorPallets.primary;

	/** The "forceState" cookie if exists */
	const cookies = getCookie(CookieNames.FORCE_STATE) ? JSON.parse(getCookie(CookieNames.FORCE_STATE) as string) : {};
	if (cookies.c) {
		// Grab category from URL if it exists
		if (!urlParams) {
			// eslint-disable-next-line no-param-reassign
			urlParams = processLibraryURL(undefined, true);
		}
		if (urlParams.c) {
			const defaultCategory = UIHelper.isInAppBrowser() ? ['model'] : ['model', 'rig', 'animation', 'material'];
			let categoryList = Array.isArray(urlParams.c) ? urlParams.c : decodeURIComponent(urlParams.c).split(',');
			categoryList = categoryList?.map((item) => item.trim()).filter((item) => item !== '') || [];
			categoryList = [...new Set(categoryList)];

			if (
				!isEqual(categoryList?.sort(), defaultCategory?.sort()) &&
				!isEmpty(categoryList) &&
				categoryList.length < 4
			) {
				[category] = categoryList;
			}
		}
	}

	// If category is valid, use the color pallet
	if (categoryOptions.includes(category)) {
		color = colorPallets[category];
	} else {
		category = '';
	}

	return !isEmpty(category) && color
		? {
				palette: {
					primary: {
						light: color?.light || 'inherit',
						main: color?.main || 'inherit',
					},
					background: {
						default: inApp ? 'transparent' : '#18181C',
					},
				},
				components: {
					MuiChip: {
						styleOverrides: {
							root: {
								backgroundColor: `${color?.main || 'inherit'} !important`,
							},
						},
					},
					MuiTextField: {
						variants: [
							{
								props: { className: 'search-bar' },
								style: {
									backgroundColor: `${color?.main || 'inherit'} !important`,
									'& .MuiInputBase-input': {
										color: '#000 !important',
									},
									'& svg': {
										color: '#000 !important',
									},
								},
							},
						],
					},
				},
				// Change the background colour of <body>
				// https://stackoverflow.com/questions/64899150/how-to-change-the-background-color-of-body-in-material-ui
		  }
		: {};
}
