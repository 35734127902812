import { UIHelper } from '@mpx-sdk/helpers/ui';
import {
	adminFeaturesAtom,
	inAppVersionAtom,
	userAtom,
	userProfileDisplayAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { VERSION } from '@mpx-sdk/shared/configs';
import EditAvatar from '@mpx-sdk/ui/components/profile/edit-profile/EditAvatar';
import { Box, MenuItem, Paper, Select, SelectChangeEvent, Stack, SxProps, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface ProfilePageSidebarProps {
	/** The style of the component. */
	sx?: SxProps;
}

/** The sidebar for the user's profile page. */
export default function ProfilePageSidebar({ sx }: ProfilePageSidebarProps): ReactElement {
	const router = useRouter();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inAppVersion = useAtomValue(inAppVersionAtom);
	const setSlugUser = useSetAtom(userProfileDisplayAtom);
	const responsiveView = UIHelper.isResponsiveView();

	/** The navigation links for the sidebar. */
	const navigation = [
		{ title: 'Edit Profile', url: '/profile/edit-profile' },
		{
			title: 'Payment Methods',
			url: '/profile/payment-methods',
			hidden: !currentUserRoles?.inDevelopment || !adminView,
		},
		{
			title: 'Manage Subscriptions',
			url: '/profile/subscriptions',
		},
		{
			title: 'Advanced Settings',
			url: '/profile/advanced-settings',
		},
		{
			title: 'Social Profiles',
			url: '/profile/social-profiles',
		},
	];

	const handleDropdownChange = (event: SelectChangeEvent<string>) => {
		const url = event.target.value;
		router.push(url);
	};

	useEffect(() => {
		if (currentUser) {
			setSlugUser(currentUser);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	return responsiveView ? (
		<Paper sx={{ width: '30%', backgroundColor: '#000', alignContent: 'center', ...sx }}>
			<Stack
				alignItems='center'
				justifyContent='center'
				sx={{
					margin: '2rem auto',
				}}
			>
				<EditAvatar
					displayBadge
					displayButton={false}
					sx={{
						backgroundColor: 'background.paper',
						boxShadow: 2,
						height: '130px !important',
						margin: 'auto',
						marginBottom: 2,
						maxHeight: '100%',
						maxWidth: '100%',
						width: '130px !important',
					}}
				/>
			</Stack>

			<Stack
				alignItems='start'
				justifyContent='start'
				sx={{
					marginBottom: '2rem',
					width: '100%',
				}}
			>
				{navigation.map(({ title, url, hidden, onClick }) => {
					if (hidden) return null;

					const isActive = url.endsWith(router.pathname);

					return (
						<Link
							key={`link-${title}`}
							href={url}
							onClick={onClick}
							style={{
								width: '100%',
							}}
						>
							<Box
								sx={{
									backgroundColor: isActive ? 'background.paper' : 'transparent',
									width: '100%',
									'&:hover': {
										backgroundColor: 'background.paper',
									},
								}}
							>
								<Typography
									key={title}
									aria-label={title}
									color={isActive ? 'primary' : 'text.primary'}
									fontWeight={isActive ? 'bold' : 'normal'}
									role='button'
									sx={{
										marginLeft: '10%',
										marginBottom: '1rem',
										padding: '0.5rem',
										borderRight: isActive ? '4px solid #00EAB7' : 'none',
									}}
								>
									{title}
								</Typography>
							</Box>
						</Link>
					);
				})}
			</Stack>

			<Box
				sx={{
					width: '100%',
				}}
			>
				<Typography
					sx={{
						color: 'text.disabled',
						marginLeft: '10%',
						marginBottom: '1rem',
						padding: '0.5rem',
						width: '100%',
					}}
				>
					Web version: {VERSION.MPX_WEB}
					{inAppVersion && (
						<>
							<br />
							In-app version: {inAppVersion}
						</>
					)}
				</Typography>
			</Box>

			<Link
				key='link-feedback'
				href='/feedback'
				style={{
					width: '100%',
				}}
			>
				<Box
					sx={{
						width: '100%',
						'&:hover': {
							backgroundColor: 'background.paper',
						},
					}}
				>
					<Typography
						key='feedback'
						aria-label='Help & Support'
						role='button'
						sx={{
							marginLeft: '10%',
							marginBottom: '1rem',
							padding: '0.5rem',
							color: 'text.disabled',
							'&:hover': {
								color: 'primary.main',
							},
						}}
					>
						Help & Support
					</Typography>
				</Box>
			</Link>
		</Paper>
	) : (
		<Select
			onChange={handleDropdownChange}
			onClose={() => setIsDropdownOpen(false)}
			onOpen={() => setIsDropdownOpen(true)}
			open={isDropdownOpen}
			sx={{
				backgroundColor: 'background.paper',
				borderRadius: '20px',
				marginBottom: '2rem',
				width: '80%',
				svg: {
					color: 'primary.main',
				},
			}}
			value={router.pathname}
		>
			{navigation.map(({ title, url, hidden }) => {
				if (hidden) return null;
				return (
					<MenuItem key={`dropdown-${title}`} value={url}>
						{title}
					</MenuItem>
				);
			})}
		</Select>
	);
}
