import { projectExportsAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import AssetDisplay from '@mpx-sdk/ui/components/assets/AssetDisplay';
import { Box, CircularProgress } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteHits } from 'react-instantsearch';

interface InfiniteHitsProps {
	/** JSX component where the hits will be displayed in */
	assetDisplayJSX?: React.ReactElement;
	/** The ID of the element to scroll to when the user clicks on a search result */
	infiniteScrollTarget?: string;
}

/** A custom InfiniteHits component that uses react-infinite-scroll-component to load more results when the user scrolls to the bottom of the page. */
export default function InfiniteHits({ assetDisplayJSX, infiniteScrollTarget, ...props }: InfiniteHitsProps) {
	const router = useRouter();
	const { query } = router;

	const [inProgressData, setInProgressData] = useState<object[]>([]);
	const projectExports = useAtomValue(projectExportsAtom);
	const { hits, showMore, isLastPage, sendEvent } = useInfiniteHits(props);

	useEffect(() => {
		if ((query?.export === 'inProgress' && isEmpty(inProgressData)) || !isEmpty(projectExports)) {
			const exportLocalStorage: any = projectExports || JSON.parse(localStorage.getItem('mpx-export') as string);

			if (exportLocalStorage && exportLocalStorage.destination === 'community') {
				setInProgressData([
					{
						...exportLocalStorage,
						type: exportLocalStorage.progress >= 100 ? 'asset' : 'export',
						category: 'model',
					},
				]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, projectExports]);

	return (
		<InfiniteScroll
			dataLength={hits.length}
			hasMore={!isLastPage}
			loader={
				<Box pb={5} textAlign='center'>
					<CircularProgress size='3rem' />
				</Box>
			}
			next={showMore}
			scrollableTarget={infiniteScrollTarget}
			scrollThreshold={0.7}
		>
			{assetDisplayJSX ? (
				React.cloneElement(assetDisplayJSX, {
					projectData: [...(inProgressData as PublicAsset[]), ...(hits as unknown as PublicAsset[])],
					onClick: (hit) => sendEvent('click', hit, 'project_viewed'),
				})
			) : (
				<AssetDisplay
					displaySort={false}
					onClick={(hit) => sendEvent('click', hit, 'project_viewed')}
					projectData={[...(inProgressData as PublicAsset[]), ...(hits as unknown as PublicAsset[])]}
				/>
			)}
		</InfiniteScroll>
	);
}
