import { BackIcon } from '@mpx-sdk/images';
import { IconButton, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

interface HorizontalAssetButtonsProps {
	direction: 'left' | 'right';

	onClick: () => void;

	customIconStyles?: React.CSSProperties;
}

export default function HorizontalAssetButtons({
	direction,
	onClick,
	customIconStyles,
}: HorizontalAssetButtonsProps): ReactElement {
	return (
		<Tooltip arrow describeChild title={direction === 'left' ? 'Scroll left' : 'Scroll right'}>
			<IconButton
				className={classNames('asset-horizontal-scroll-button', {
					'asset-horizontal-scroll-left': direction === 'left',
					'asset-horizontal-scroll-right': direction === 'right',
				})}
				onClick={onClick}
				sx={customIconStyles}
			>
				<BackIcon />
			</IconButton>
		</Tooltip>
	);
}
