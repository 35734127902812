import { updateCurrentUser } from '@mpx-sdk/helpers/users';
import { savEditMode, userAtom, userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import EditAvatar from '@mpx-sdk/ui/components/profile/edit-profile/EditAvatar';
import EditDetails from '@mpx-sdk/ui/components/profile/edit-profile/EditDetails';
import EditSocials from '@mpx-sdk/ui/components/profile/edit-profile/EditSocials';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { isEmpty, isEqual } from 'lodash';
import { ReactElement, useEffect } from 'react';

interface EditProfileProps {
	onClose?: () => void;
	/** Whether the edit profile dialog is open is not [default false] */
	open?: boolean;
}

export default function EditProfile({ onClose, open = false }: EditProfileProps): ReactElement | null {
	const [currentUser, setCurrentUser] = useAtom(userAtom);
	const [slugUser, setSlugUser] = useAtom(userProfileDisplayAtom);
	const setEditMode = useSetAtom(savEditMode);

	async function handleSaveChanges() {
		if (slugUser) {
			const data: Record<string, string> = {};

			const dataModalEntryTypeElements = document.querySelectorAll<HTMLElement>(
				'#edit-profile-dialog [data-modal-entry-type]',
			);

			dataModalEntryTypeElements.forEach((element) => {
				const attribute = element.getAttribute('data-modal-entry-type');
				const textarea = element.querySelector('textarea');
				if (attribute && textarea) {
					data[attribute] = textarea.value;
				}
			});

			// Check if any socials were deleted, and if were, add to data as an empty value
			const socials = [
				'artstation',
				'facebook',
				'github',
				'instagram',
				'linkedin',
				'sketchfab',
				'twitter',
				'website',
				'youtube',
			];
			socials.forEach((social) => {
				// If the social is not in the data object, but has a value in the slugUser object, add it to the data object
				if (!data[social] && slugUser[social]) {
					data[social] = '';
				}
			});

			if (data && !isEmpty(data) && !isEmpty(currentUser) && currentUser?.id === slugUser?.id) {
				await updateCurrentUser(data);

				setSlugUser({ ...slugUser, ...data });
			}

			setEditMode(false);
			onClose?.();
		}
	}

	useEffect(() => {
		if (!isEmpty(currentUser) && currentUser?.id === slugUser?.id && !isEqual(currentUser, slugUser)) {
			setCurrentUser(slugUser);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugUser, currentUser]);

	return slugUser ? (
		<Dialog id='edit-profile-dialog' onClose={onClose} open={open}>
			<DialogTitle
				sx={{
					position: 'relative',
				}}
			>
				Edit Profile
				{onClose ? (
					<IconButton
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: 'icons.inactive',
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>

			<DialogContent>
				<Stack alignItems='flex-start' direction='column' justifyContent='flex-start' spacing={2}>
					<EditAvatar />

					<EditDetails />

					<EditSocials />

					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<Tooltip arrow describeChild title='Save changes and update profile'>
							<Button
								className='sav-header-open-button'
								color='primary'
								fullWidth
								onClick={() => handleSaveChanges()}
								sx={{
									backgroundColor: 'primary.main',
									margin: 'auto',
									maxWidth: '280px',
									'&:hover': {
										backgroundColor: 'primary.light',
									},
								}}
								variant='contained'
							>
								Save Changes
							</Button>
						</Tooltip>
					</div>
				</Stack>
			</DialogContent>
		</Dialog>
	) : null;
}
