import { getShowcaseTags, projectsRetrieved } from '@mpx-sdk/helpers/assets';
import { newLibraryState } from '@mpx-sdk/helpers/library';
import { activeSearchQuery, libraryDisplayType, libraryURLParamsAtom } from '@mpx-sdk/shared/atoms';
import { Chip } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

interface ShowcaseTagsProps {
	/** Number of tags to display */
	howMany?: number;
}

export default function ShowcaseTags({ howMany }: ShowcaseTagsProps): Array<ReactElement> | null {
	const router = useRouter();
	const { query } = router;

	/** JSX for showcase tags */
	const [displayTagsJSX, setDisplayTagsJSX] = useState<Array<ReactElement>>([]);

	const [activeSearchValue, setActiveSearchValue] = useAtom(activeSearchQuery);

	const libraryType = useAtomValue(libraryDisplayType);

	const setLibraryURLParams = useSetAtom(libraryURLParamsAtom);

	function displayTags(tags) {
		if (!isEmpty(tags)) {
			/** Showcase tags' JSX */
			const tagsJSX: Array<ReactElement> = [];

			tags.forEach((tag) => {
				tagsJSX.push(
					<Chip
						key={tag}
						className='search-filters-tags-showcase'
						clickable
						color='default'
						data-project-tag={`showcase-${tag}`}
						hidden={Boolean(query?.f?.toString() === 'true' && query?.q)}
						label={tag}
						onClick={() => {
							if (window.location.pathname === '/library') {
								setActiveSearchValue(tag);
								setLibraryURLParams(newLibraryState({ q: tag.trim() }));
							} else {
								router.push(`/library?q=${tag}`);
							}
						}}
						sx={{ fontSize: 16, padding: '20px 10px' }}
					/>,
				);
			});

			setDisplayTagsJSX(tagsJSX);
		}
	}

	function getMostFrequentTags(howMany = 6) {
		const tagsFrequency = {};
		const projectData = projectsRetrieved[libraryType];

		projectData.forEach((project) => {
			if (project.tags) {
				project.tags.forEach((tag) => {
					if (tagsFrequency[tag]) {
						tagsFrequency[tag] += 1;
					} else {
						tagsFrequency[tag] = 1;
					}
				});
			}
		});

		const tags = Object.keys(tagsFrequency).sort((a, b) => tagsFrequency[b] - tagsFrequency[a]);

		displayTags(tags.slice(0, howMany));
	}

	/**
	 * Get showcase tags (~55% featured tags, ~45% trending tags) and create respective JSX for them
	 * @param {Number} [howMany=9] How many tags to return (default 9)
	 * @returns {Array} List of showcase tags
	 * @example <caption>Use this function to obtain a list of showcase tags. If in the instance that there are less than ~55% of howMany featured tags, the remaining will be trending tags</caption>
	 * getTagsShowcase(9)
	 * // returns null (does not return anything but rather creates JSX for the showcase tags under the showcaseTags state)
	 * @async
	 */
	async function getTagsShowcase(howMany = 6) {
		/** List of showcase tags */
		let showcase = (await getShowcaseTags(howMany)) || [];

		// Remove any tags that include 'broken', 'qa-test' or 'qatest':
		showcase = showcase.filter(
			(tag) =>
				!tag.toLowerCase().includes('broken') &&
				!tag.toLowerCase().includes('qatest') &&
				!tag.toLowerCase().includes('qa-test'),
		);

		displayTags(showcase);
	}

	async function getTags(howMany = 6) {
		if (!isEmpty(activeSearchValue) && projectsRetrieved[libraryType]) {
			getMostFrequentTags(howMany);
		} else {
			await getTagsShowcase(howMany);
		}
	}

	useEffect(() => {
		getTags(howMany || 6);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSearchValue, libraryType]);

	return !isEmpty(displayTagsJSX) ? displayTagsJSX : null;
}
