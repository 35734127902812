/* eslint-disable no-nested-ternary */
import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import CategoryRefinementList from '@mpx-sdk/ui/components/algolia/CategoryRefinementList';
import SortBy from '@mpx-sdk/ui/components/algolia/SortBy';
import TagRefinementList from '@mpx-sdk/ui/components/algolia/TagRefinementList';
import { Grid, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement } from 'react';

interface ContentRefinementProps {
	/** Default or current Algolia search configurations */
	searchSettings: any;
}

/** Renders a list of sorting, tags categories that can be used to refine search results. */
export default function ContentRefinement({ searchSettings }: ContentRefinementProps): ReactElement | null {
	const inApp = useAtomValue(inAppBrowserAtom);
	const isMdScreenSize = useMediaQuery((theme) => (theme as any).breakpoints.up('md'));

	if (!searchSettings) {
		return null;
	}

	const sortByOptions = searchSettings.sortByOptions.map((item) => ({
		value: item.indexName,
		label: item.label,
	}));

	const sortGridItem = (
		<Grid item md={2} sx={{ paddingRight: '0.5rem' }} xs={!inApp ? 6 : 5}>
			<SortBy defaultRefinement={searchSettings.sortByIndex} items={sortByOptions} />
		</Grid>
	);

	const categoryGridItem = (
		<Grid
			item
			md={2}
			sx={{
				paddingLeft: '0.5rem',
			}}
			xs={6}
		>
			<CategoryRefinementList attribute='category' facetOrdering={false} />
		</Grid>
	);

	const tagGridItem = (
		<Grid item md={8} xs={12}>
			<TagRefinementList attribute='tags' operator='and' />
		</Grid>
	);

	return (
		<Grid
			alignItems='center'
			container
			direction='row'
			justifyContent='center'
			sx={{
				maxWidth: {
					xs: '95%',
					md: '80%',
				},
				margin: 'auto',
				paddingTop: !inApp ? '1rem' : 'initial',
				'.MuiGrid-item': {
					'&:first-of-type': {
						paddingLeft: 0,
					},
					'&:last-child': {
						paddingRight: 0,
					},
				},
			}}
		>
			{inApp ? (
				<>
					{tagGridItem}
					{categoryGridItem}
				</>
			) : isMdScreenSize ? (
				<>
					{sortGridItem}
					{tagGridItem}
					{categoryGridItem}
				</>
			) : (
				<>
					{sortGridItem}
					{categoryGridItem}
					{tagGridItem}
				</>
			)}
		</Grid>
	);
}
