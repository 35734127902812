import { ReactElement, ReactNode } from 'react';
import { useInstantSearch } from 'react-instantsearch';

interface NoResultsBoundaryProps {
	children: ReactNode | ReactElement;
	fallback: ReactNode | ReactElement;
}

/**
 * A component that checks if there are any search results and
 * conditionally renders either the children or fallback content.
 * @returns {ReactNode} The children or fallback content.
 */
function NoResultsBoundary({ children, fallback }: NoResultsBoundaryProps): ReactNode | ReactElement {
	const { results } = useInstantSearch();

	// The `__isArtificial` flag makes sure not to display the No Results message
	// when no hits have been returned yet.
	// eslint-disable-next-line no-underscore-dangle
	if (!results.__isArtificial && results.nbHits === 0) {
		return (
			<>
				{fallback}
				<div hidden>{children}</div>
			</>
		);
	}

	return children;
}

export default NoResultsBoundary;
