import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import Bookmarks from '@mpx-sdk/ui/components/profile/display-container/Bookmarks';
import Display from '@mpx-sdk/ui/components/profile/display-container/Display';
import Gallery from '@mpx-sdk/ui/components/profile/display-container/Gallery';
import Header from '@mpx-sdk/ui/components/profile/display-container/Header';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

export default function UserDisplay(): ReactElement | null {
	const slugUser = useAtomValue(userProfileDisplayAtom);

	return !isEmpty(slugUser) ? (
		<>
			<Header />
			<Display />
			<Gallery />
			<Bookmarks />
		</>
	) : null;
}
