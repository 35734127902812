/* eslint-disable no-nested-ternary */
import { findProjectThumbnailUrl, handleOpeningAsset } from '@mpx-sdk/helpers/assets';
import { matureWordsFilter } from '@mpx-sdk/helpers/input';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import {
	inAppBrowserAtom,
	openSlotsDialog,
	projectExportsAtom,
	singleAssetViewAtom,
	userAtom,
	userSlotsAtom,
} from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import AssetCardExportTooltip from '@mpx-sdk/ui/components/assets/card/AssetCardExportTooltip';
import AssetCardOverlay from '@mpx-sdk/ui/components/assets/card/AssetCardOverlay';
import AssetThumbnail from '@mpx-sdk/ui/components/assets/card/AssetThumbnail';
import LinkWrapper from '@mpx-sdk/ui/components/core/LinkWrapper';
import { Card, CardActionArea, CardMedia, CircularProgress, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement, memo, useEffect, useState } from 'react';

interface AssetCardProps {
	/** The project data to display in the modal */
	projectData: PublicAsset;

	/** The function to call when a project is clicked */
	onClick?: (projectData, type) => void;

	/** The thumbnail URL to display in the modal */
	thumbnailUrl?: string;

	/** Whether the delete button should appear on the asset card */
	deleteAsset?: boolean;

	/** Whether you want to hide the asset card's buttons */
	hideMediaOverlay?: boolean;

	/** Custom link href if want to ignore the base SAV URL for assets */
	href?: string | null;

	/** Whether to force display the title and not let it be dependent on view style */
	forceDisplayTitle?: boolean;
}

/**
 * Display projects within the library
 * @returns {ReactElement} Asset card JSX element
 */
function AssetCard({
	deleteAsset,
	forceDisplayTitle = false,
	hideMediaOverlay,
	href,
	onClick,
	projectData,
	thumbnailUrl,
}: AssetCardProps): ReactElement {
	const [userSlots, setUserSlots] = useAtom(userSlotsAtom);
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const setOpenSlots = useSetAtom(openSlotsDialog);
	const setSingleAssetView = useSetAtom(singleAssetViewAtom);
	const responsiveView = UIHelper.isResponsiveView();

	const thumbnailUrlState =
		thumbnailUrl || findProjectThumbnailUrl(projectData?.files || [], projectData?.thumbnailUrl);

	// Following related to project exports
	const [loadingProgress, setLoadingProgress] = useState(0);
	const projectExports = useAtomValue(projectExportsAtom);
	/** Toggles the display of the export asset tooltip */
	const [openExportAssetTooltip, setOpenExportAssetTooltip] = useState(false);

	/** Generates the card media component for the asset card */
	function generateCardMedia(): ReactElement {
		if (projectData?.id && ['empty', 'export'].includes(projectData.id as string) && projectData?.type) {
			if (['continue', 'private'].includes(projectData.type)) {
				return (
					<CardMedia
						key={`project-key-thumbnail-${projectData?.id}-${projectData?.type}`}
						className='asset-thumbnail asset-thumbnail-empty'
						data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
						title='Empty slot'
					>
						<span className='asset-thumbnail-empty-text' />
					</CardMedia>
				);
			}

			if (projectData.type === 'export') {
				return (
					<CardMedia
						key={`project-key-thumbnail-${projectData?.id}-${projectData?.type}`}
						className='asset-thumbnail asset-thumbnail-empty'
						data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
						title='Exporting in progress ...'
					>
						<span className='asset-thumbnail-empty-text'>
							<CircularProgress
								value={loadingProgress}
								variant={
									loadingProgress <= 0 || loadingProgress >= 100 ? 'indeterminate' : 'determinate'
								}
							/>
						</span>
					</CardMedia>
				);
			}
		}

		return (
			<CardMedia
				className='asset-thumbnail'
				data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
				sx={{
					position: 'relative',
					width: '100%',
					height: '100%',
				}}
				title={
					!isEmpty(projectData?.title)
						? !currentUser?.matureFilter
							? projectData?.title
							: matureWordsFilter(projectData?.title)
						: 'Untitled Project'
				}
			>
				<AssetThumbnail
					alt={projectData?.description ?? projectData?.title ?? 'Untitled Project'}
					src={thumbnailUrlState}
				/>
			</CardMedia>
		);
	}

	let clicked = false;
	const handleClickingProject = (type = inApp ? 'download' : 'info') => {
		if (projectData?.id !== 'empty' && !clicked) {
			clicked = true;

			setTimeout(() => {
				clicked = false;
			}, 500);

			if (onClick) {
				onClick(projectData, type);
			}

			if (type === 'download') {
				handleOpeningAsset({
					projectData,
					setMaxSlotsOpen: setOpenSlots,
					setSlots: setUserSlots,
					slots: userSlots,
				});
			} else {
				setSingleAssetView(projectData);
			}
		}

		return null;
	};

	useEffect(() => {
		if (projectData.destination) {
			if (projectExports?.progress && loadingProgress !== projectExports?.progress) {
				setLoadingProgress(projectExports?.progress || 0);

				if (!hideMediaOverlay && projectData?.destination && (projectExports?.progress || 0)) {
					setOpenExportAssetTooltip(true);

					if (projectData.id) {
						DataLayer.triggerMeasurementEvent('assetEvent', {
							destination: projectData.destination,
							event_name: `export_${projectData.destination}_location`,
							title: projectData.title,
							userId: currentUser?.id,
							projectId: projectData.id,
						});
					}
				}
			} else {
				setLoadingProgress(0);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectExports, projectData]);

	return (
		projectData && (
			<LinkWrapper
				href={
					href?.toString() ??
					(!inApp && typeof projectData.id === 'number' ? `/library/${projectData?.id}` : '')
				}
				ignoreLink
			>
				<AssetCardExportTooltip
					describeChild
					onClick={() => setOpenExportAssetTooltip(false)}
					onClose={() => setOpenExportAssetTooltip(false)}
					open={openExportAssetTooltip}
					title={projectData?.tooltip}
				>
					<Card
						className={classNames('asset-card', {
							'asset-card-export-overlay': projectData?.destination && loadingProgress >= 100,
						})}
						sx={{
							'&:hover': {
								'.asset-media-overlay': {
									display: 'flex',
								},
							},
						}}
					>
						<CardActionArea className='asset-card-action-area' onClick={() => handleClickingProject()}>
							{generateCardMedia()}

							<Stack
								alignItems='flex-start'
								className={classNames('asset-media-overlay asset-media-overlay-title', {
									'asset-media-overlay-title-desktop': responsiveView,
								})}
								direction='row'
								justifyContent='flex-start'
								spacing={1}
								sx={{
									display: forceDisplayTitle || !responsiveView ? 'flex' : 'none',
								}}
							>
								<Typography
									sx={
										responsiveView
											? {
													maxWidth: '56%',
											  }
											: undefined
									}
								>
									{projectData.title ||
										(projectData?.type &&
											['continue', 'private'].includes(projectData?.type) &&
											'Empty Slot') ||
										'Untitled Project'}
								</Typography>
							</Stack>
						</CardActionArea>

						{!hideMediaOverlay && projectData?.id !== 'empty' && projectData.type !== 'export' && (
							<AssetCardOverlay
								deleteAsset={deleteAsset}
								onClick={handleClickingProject}
								projectData={projectData}
							/>
						)}
					</Card>
				</AssetCardExportTooltip>
			</LinkWrapper>
		)
	);
}

export default memo(AssetCard);
