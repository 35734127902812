import { Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface SearchTextInputProps extends React.ComponentProps<typeof TextField> {
	query?: string;
}

export default function SearchTextInput({ query, ...props }: SearchTextInputProps) {
	const [search, setSearch] = useState<string>(query ?? '');

	useEffect(() => {
		if (query && search !== query) {
			setSearch(query);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return (
		<Stack
			alignItems='center'
			className='search-bar-container'
			direction='row'
			justifyContent='center'
			spacing={2}
			sx={{
				maxWidth: {
					xs: '95%',
					md: '80%',
				},
			}}
		>
			<TextField
				aria-label='Library search bar text area'
				className='search-bar'
				margin='normal'
				name='search'
				onChange={(e) => setSearch(e.target.value)}
				placeholder='Search'
				sx={{
					backgroundColor: 'background.paper',
					borderColor: 'background.paper',
					borderRadius: 28,
					color: 'text.primary',
					margin: '1px',
					width: '100%',
				}}
				value={search}
				variant='outlined'
				{...props}
			/>
		</Stack>
	);
}
