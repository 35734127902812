import { userAtom } from '@mpx-sdk/shared/atoms';
import Env from '@mpx-sdk/shared/configs/env';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { toast } from 'react-toastify';

export default function ChangePassword() {
	const user = useAtomValue(userAtom);

	return (
		<>
			<Typography
				sx={{
					lineHeight: '78.5px',
					letterSpacing: '-0.56px',
					fontSize: '1.75rem',
				}}
			>
				Reset your password
			</Typography>

			<Button
				onClick={() => {
					axios
						.post(`${Env.MPS_API_HOST}/users/request-reset-password`, {
							email: user?.email,
						})
						.then(() => {
							toast.info('Password reset email sent');
						})
						.catch((error) => {
							console.error(error);
							toast.error('Something went wrong. Please try again later');
						});
				}}
				sx={{
					fontWeight: '600',
				}}
				variant='contained'
			>
				Request Change Password
			</Button>
		</>
	);
}
