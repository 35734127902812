/* eslint-disable no-nested-ternary */
import { handleHorizontalGridResize, scrollDirection } from '@mpx-sdk/helpers/ui';
import { assetGridBreakpoints, maxWidthSizeValue, storageGridBreakpoints } from '@mpx-sdk/shared/configs';
import { PublicAsset } from '@mpx-sdk/types';
import AssetCard from '@mpx-sdk/ui/components/assets/card/AssetCard';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';

const HorizontalAssetButtons = lazy(
	() => import('@mpx-sdk/ui/components/assets/grid/horizontal/HorizontalAssetButtons'),
);

interface AssetGridProps {
	/** List of assets/projects to display */
	projectData: Array<PublicAsset>;
	// Props related to asset card
	/** Whether you want to hide the asset card's buttons */
	hideMediaOverlay?: boolean;
	/** Whether the delete button should appear on the asset card */
	deleteAsset?: boolean;
	/** The function to call when a project is clicked */
	onClick?: () => void;
	// Props related to the appearance of the asset card or the asset grid
	/** Whether the grid should have rounded corners or not */
	roundedCorners?: boolean;
	/** Whether the grid should have horizontal scroll or not */
	horizontalScroll?: boolean;
	/** Whether a subtext is below the grid */
	subtextBelow?: boolean;
	/** The maximum number of grid rows to display */
	maxNumberOfRows?: number;
	/** What function to call when the user scrolls to the end of the grid */
	onScrollEnd?: () => void;
	// Props related to giving asset display certain behavior
	/** The behavior to give the asset grid */
	displayBehavior?: 'default' | 'trending' | 'smallerScreen';
}

export default function AssetGrid({
	deleteAsset,
	displayBehavior,
	hideMediaOverlay,
	horizontalScroll = false,
	maxNumberOfRows,
	onClick,
	onScrollEnd,
	projectData,
	roundedCorners,
	subtextBelow,
}: AssetGridProps): ReactElement {
	const [showScrollButtons, setShowScrollButtons] = useState(horizontalScroll);

	const gridRef = useRef<HTMLDivElement>(null);
	const displayFirstAssetLarge = useMemo(() => {
		if (displayBehavior === 'smallerScreen' && projectData.length > 7) {
			return true;
		}

		return false;
	}, [displayBehavior, projectData]);

	// Functions related to updating the display of assets
	function handleHorizontalScroll() {
		if (gridRef.current && onScrollEnd) {
			handleHorizontalGridResize(gridRef?.current, setShowScrollButtons);

			const scrollThreshold = 0.5;

			// Find the max scroll value for the grid
			const maxScroll = gridRef.current.scrollWidth - gridRef.current.clientWidth;

			// Check whether the user has scrolled past the threshold
			if (maxScroll && gridRef.current.scrollLeft >= maxScroll * scrollThreshold) {
				// If so, load more data
				onScrollEnd();
			}
		}
	}

	async function handleGridResize() {
		if (gridRef.current) {
			if (maxNumberOfRows) {
				// We can set the max height of the grid by multiplying the number of rows by the height of each row
				// If class asset-grid-item exists, get that height
				const gridItemHeight = document.querySelector('.asset-grid-item')?.clientHeight;

				if (gridItemHeight) {
					// Make the grid height the height of the grid item times the number of rows
					gridRef.current.style.maxHeight = `${gridItemHeight * maxNumberOfRows}px`;
					// Overflow should be hidden so that the grid doesn't show more than the max number of rows
					gridRef.current.style.overflow = 'hidden';
				}
			}
		}
	}

	useEffect(() => {
		if (horizontalScroll) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			handleHorizontalScroll();
		}

		handleGridResize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	useEffect(() => {
		if (horizontalScroll) {
			handleHorizontalScroll();
			window.addEventListener('scroll', handleHorizontalScroll);
		}

		handleGridResize();
		window.addEventListener('resize', handleGridResize);

		return () => {
			if (horizontalScroll) {
				window.removeEventListener('scroll', handleHorizontalScroll);
			}

			window?.removeEventListener('resize', handleGridResize);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={classNames({
				'asset-horizontal-scroll-grid-container': horizontalScroll,
			})}
		>
			{showScrollButtons ? (
				<Suspense fallback={null}>
					<HorizontalAssetButtons
						direction='left'
						onClick={() => scrollDirection(gridRef?.current, 'left')}
					/>
					<HorizontalAssetButtons
						direction='right'
						onClick={() => scrollDirection(gridRef?.current, 'right')}
					/>
				</Suspense>
			) : null}

			<Grid
				ref={gridRef}
				alignItems='stretch'
				className={classNames('asset-display', {
					'asset-display-rounded': roundedCorners,
					'asset-display-see-more': subtextBelow,
				})}
				container
				direction='row'
				justifyContent='flex-start'
				spacing={0}
				sx={{
					overflowX: horizontalScroll ? 'scroll' : 'inherit',
					'&::-webkit-scrollbar': {
						display: horizontalScroll ? 'none' : 'inherit',
					},
					msOverflowStyle: horizontalScroll ? 'none' : 'inherit',
					scrollbarWidth: horizontalScroll ? 'none' : 'inherit',
				}}
				wrap={horizontalScroll ? 'nowrap' : 'wrap'}
			>
				{projectData.map((project: PublicAsset, index: number) => (
					<Grid
						key={`asset-grid-item-${project.id !== 'empty' ? project.id : `empty-${index}`}-${
							project?.title || 'untitled'
						}`}
						className='asset-grid-item'
						data-grid-item-id={`asset-grid-item-${project.id}-${project?.title || 'untitled'}`}
						data-grid-item-title={project?.title || 'untitled'}
						data-grid-item-type={horizontalScroll ? 'horizontal-asset' : 'asset'}
						item
						lg={
							displayFirstAssetLarge && index === 0
								? 12
								: displayBehavior === 'smallerScreen'
								? storageGridBreakpoints.lg
								: assetGridBreakpoints.lg
						}
						md={
							displayFirstAssetLarge && index === 0
								? 12
								: displayBehavior === 'smallerScreen'
								? storageGridBreakpoints.md
								: assetGridBreakpoints.md
						}
						sm={
							displayFirstAssetLarge && index === 0
								? 12
								: displayBehavior === 'smallerScreen'
								? storageGridBreakpoints.sm
								: assetGridBreakpoints.sm
						}
						sx={
							displayFirstAssetLarge && index === 0
								? {
										aspectRatio: 'initial !important',
										img: {
											objectFit: 'contain !important',
										},
										minHeight: {
											xs: `calc(${storageGridBreakpoints.xs}/12 * ${maxWidthSizeValue}vw)`,
											sm: `calc(${storageGridBreakpoints.sm}/12 * ${maxWidthSizeValue}vw)`,
											md: `calc(${storageGridBreakpoints.md}/12 * ${maxWidthSizeValue}vw)`,
											lg: `calc(${storageGridBreakpoints.lg}/12 * ${maxWidthSizeValue}vw)`,
											xl: `calc(${storageGridBreakpoints.xl}/12 * ${maxWidthSizeValue}vw)`,
										},
								  }
								: {
										minHeight: {
											xs: `calc(${
												displayBehavior === 'smallerScreen'
													? storageGridBreakpoints.xs
													: assetGridBreakpoints.xs
											}/12 * ${maxWidthSizeValue}${
												displayBehavior === 'smallerScreen' ? '%' : 'vw'
											})`,
											sm: `calc(${
												displayBehavior === 'smallerScreen'
													? storageGridBreakpoints.sm
													: assetGridBreakpoints.sm
											}/12 * ${maxWidthSizeValue}${
												displayBehavior === 'smallerScreen' ? '%' : 'vw'
											})`,
											md: `calc(${
												displayBehavior === 'smallerScreen'
													? storageGridBreakpoints.md
													: assetGridBreakpoints.md
											}/12 * ${maxWidthSizeValue}${
												displayBehavior === 'smallerScreen' ? '%' : 'vw'
											})`,
											lg: `calc(${
												displayBehavior === 'smallerScreen'
													? storageGridBreakpoints.lg
													: assetGridBreakpoints.lg
											}/12 * ${maxWidthSizeValue}${
												displayBehavior === 'smallerScreen' ? '%' : 'vw'
											})`,
											xl: `calc(${
												displayBehavior === 'smallerScreen'
													? storageGridBreakpoints.xl
													: assetGridBreakpoints.xl
											}/12 * ${maxWidthSizeValue}${
												displayBehavior === 'smallerScreen' ? '%' : 'vw'
											})`,
										},
								  }
						}
						xl={
							displayFirstAssetLarge && index === 0
								? 12
								: displayBehavior === 'smallerScreen'
								? storageGridBreakpoints.xl
								: assetGridBreakpoints.xl
						}
						xs={
							displayFirstAssetLarge && index === 0
								? 12
								: displayBehavior === 'smallerScreen'
								? storageGridBreakpoints.xs
								: assetGridBreakpoints.xs
						}
					>
						<AssetCard
							deleteAsset={deleteAsset}
							forceDisplayTitle={
								displayBehavior && ['smallerScreen', 'trending'].includes(displayBehavior)
							}
							hideMediaOverlay={hideMediaOverlay}
							href={displayBehavior === 'trending' ? `/library?q=${project.title}` : null}
							onClick={onClick}
							projectData={project}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
}
