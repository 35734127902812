import { FilterIcon } from '@mpx-sdk/images';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';

interface SearchSettingsButtonProps {
	onClick?: () => void;
}

export default function SearchSettingsButton({ onClick }: SearchSettingsButtonProps): ReactElement {
	return (
		<IconButton
			aria-label='Search settings and filters'
			onClick={(e) => {
				e.stopPropagation();
				onClick?.();
			}}
		>
			<FilterIcon />
		</IconButton>
	);
}
