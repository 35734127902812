import { toggleLikeProject } from '@mpx-sdk/helpers/assets';
import { BookmarkIcon, FlagIcon } from '@mpx-sdk/images';
import { flagDialogOpenAtom, flagProjectDataAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactElement, useEffect, useState } from 'react';

interface OverlayMoreContentProps {
	/** The function to call when the modal is closed */
	onClose?: () => void;
	/** The project data to display in the modal */
	projectData: PublicAsset;
}

const typographyStyle = {
	flexGrow: 1,
	textAlign: 'left',
};

const stackStyle = {
	backgroundColor: 'inherit',
	border: '1px solid rgba(0, 0, 0, 0.1)', // add border with 0.1 opacity
	color: 'inherit',
	cursor: 'pointer',
	fontSize: '0.8rem',
	minWidth: '100%',
	padding: '2px 8px',
	'&:first-of-type': {
		backgroundColor: 'primary.main',
		color: 'text.primary',
		padding: '8px 10px',
		'& > p': {
			fontSize: '0.9rem',
		},
	},
	'&:hover': {
		backgroundColor: 'primary.main',
		color: 'text.primary',
	},
};

/** A component that displays an overlay with more action buttons for a project asset. */
export default function OverlayMoreContent({ projectData, onClose }: OverlayMoreContentProps): ReactElement {
	const [isBookmarked, setIsBookmarked] = useState(projectData?.liked);
	const currentUser = useAtomValue(userAtom);
	const setFlagDialogOpen = useSetAtom(flagDialogOpenAtom);
	const setFlagProjectData = useSetAtom(flagProjectDataAtom);
	const themes = useTheme();

	const handleBookmarkProject = async () => {
		projectData.liked = !projectData.liked;
		setIsBookmarked(projectData.liked);

		await toggleLikeProject(Number(projectData?.id), 'bookmark');
	};

	const handleFlagProject = () => {
		setFlagProjectData(projectData);
		setFlagDialogOpen(true);
	};

	useEffect(() => {
		if (typeof projectData?.id !== 'number') {
			onClose?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack
			direction='column'
			sx={{
				backgroundColor: 'background.paper',
				bottom: 0,
				height: '100%',
				left: 0,
				overflow: 'auto',
				position: 'absolute',
				right: 0,
				top: 0,
				width: '100%',
				zIndex: 1,
			}}
		>
			{/* Project title */}
			<Stack
				alignItems='center'
				direction='row'
				justifyContent='space-between'
				onClick={() => {
					onClose?.();
				}}
				sx={stackStyle}
			>
				<Typography sx={typographyStyle}>{projectData?.title || 'Untitled Asset'}</Typography>
				<MoreVertIcon />
			</Stack>

			{/* Bookmark */}
			{currentUser && typeof projectData?.id === 'number' && currentUser.id !== projectData?.user?.id && (
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='space-between'
					onClick={handleBookmarkProject}
					sx={stackStyle}
				>
					<Typography sx={typographyStyle} variant='subtitle1'>
						Bookmark
					</Typography>
					<BookmarkIcon
						sx={{
							color: isBookmarked
								? `${themes.palette.icons.active} !important`
								: `${themes.palette.icons.inactive} !important`,
						}}
					/>
				</Stack>
			)}

			{/* Flag */}
			{typeof projectData?.id === 'number' && (
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='space-between'
					onClick={handleFlagProject}
					sx={stackStyle}
				>
					<Typography sx={typographyStyle} variant='subtitle1'>
						Flag
					</Typography>
					<FlagIcon />
				</Stack>
			)}
		</Stack>
	);
}
