import { userService } from '@mpx-sdk/api-client';
import { ArtStationLogoIcon, SketchFabLogoIcon } from '@mpx-sdk/images';
import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import EditText from '@mpx-sdk/ui/components/single-asset-view/components/EditText';
import {
	Close as CloseIcon,
	Facebook,
	GitHub,
	Instagram,
	Language,
	LinkedIn,
	Twitter,
	YouTube,
} from '@mui/icons-material';
import { Button, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function EditSocials() {
	const slugUser = useAtomValue(userProfileDisplayAtom);

	const [socials, setSocials] = useState<{ name: string; value: string }[]>([]);

	const iconStyles = { color: 'icons.inactive', marginRight: '1%' };
	const customIconsStyle = {
		color: 'icons.inactive',
		margin: '0 1% 0 0 !important',
	};

	// The key name is slugUser would be the lowercase version of the social name
	const socialLinks = [
		{
			name: 'Sketchfab',
			icon: <SketchFabLogoIcon sx={customIconsStyle} />,
			maxChars: 128,
		},
		{
			name: 'ArtStation',
			icon: <ArtStationLogoIcon sx={customIconsStyle} />,
			maxChars: 60,
		},
		{
			name: 'Twitter',
			icon: <Twitter sx={iconStyles} />,
			maxChars: 15,
		},
		{
			name: 'GitHub',
			icon: <GitHub sx={iconStyles} />,
			maxChars: 39,
		},
		{
			name: 'Instagram',
			icon: <Instagram sx={iconStyles} />,
			maxChars: 30,
		},
		{
			name: 'LinkedIn',
			icon: <LinkedIn sx={iconStyles} />,
			maxChars: 60,
		},
		{
			name: 'Facebook',
			icon: <Facebook sx={iconStyles} />,
			maxChars: 50,
		},
		{
			name: 'YouTube',
			icon: <YouTube sx={iconStyles} />,
			maxChars: 20,
		},
		{
			name: 'Website',
			icon: <Language sx={iconStyles} />,
			maxChars: 128,
		},
	];

	const addSocial = (name: string) => {
		if (!socials.some((social) => social.name === name)) {
			setSocials((prevSocials) => [
				...prevSocials,
				{
					name,
					value: slugUser?.[name.toLowerCase()] || '',
				},
			]);
		}
	};

	const removeSocial = (name: string) => {
		setSocials((prevSocials) => prevSocials.filter((social) => social.name !== name));
	};

	const renderSocials = () =>
		socials.map((social) => (
			<Stack
				key={social.name}
				alignItems='center'
				data-social-name={social.name}
				direction='row'
				spacing={2}
				sx={{
					width: '100%',
				}}
			>
				{socialLinks.find((link) => link.name === social.name)?.icon}

				<EditText
					enableAdmin={false}
					maxChar={socialLinks.find((link) => link.name === social.name)?.maxChars}
					projectData={slugUser}
					type={social.name.toLowerCase()}
				/>

				<IconButton aria-label='remove social link' onClick={() => removeSocial(social.name)} size='small'>
					<CloseIcon />
				</IconButton>
			</Stack>
		));

	const handleSaveChanges = () => {
		const socialElements = document.querySelectorAll('[data-social-name]');

		const existingSocials = Array.from(socialElements).reduce((acc, element) => {
			const socialName = element.getAttribute('data-social-name');
			const inputElement = element.querySelector('.MuiInputBase-input');
			const value = inputElement?.value ?? null;

			if (socialName && inputElement) {
				const propertyName = socialName.toLowerCase();
				acc[propertyName] = value;
			}

			return acc;
		}, {});

		const updatedSocials = socialLinks.reduce((acc, link) => {
			const propertyName = link.name.toLowerCase();
			if (propertyName in existingSocials) {
				acc[propertyName] = existingSocials[propertyName];
			} else {
				acc[propertyName] = null;
			}
			return acc;
		}, {});

		userService
			.updateSocials(updatedSocials)
			.then(() => {
				toast.success('Socials updated successfully');
			})
			.catch((err) => {
				console.error(err);
				toast.error('Error updating socials');
			});
	};

	useEffect(() => {
		// If the user has socials, set the socials state to the user's socials
		if (slugUser) {
			const socials = socialLinks
				.filter((social) => slugUser[social.name.toLowerCase()])
				.map((social) => ({
					name: social.name,
					value: slugUser[social.name.toLowerCase()],
				}));

			setSocials(socials);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return slugUser ? (
		<Stack
			alignItems='center'
			direction='column'
			justifyContent='center'
			spacing={2}
			sx={{
				width: '100%',
			}}
		>
			{/* Dropdown for socials */}
			<TextField
				select
				size='small'
				sx={{
					width: '100%',
				}}
				value='Add social link'
				variant='outlined'
			>
				<MenuItem
					disabled
					sx={{
						display: 'none',
					}}
					value='Add social link'
				>
					Add social link
				</MenuItem>
				{socialLinks.map(({ name, icon }) => (
					<MenuItem key={name} onClick={() => addSocial(name)} value={name}>
						{icon} {name}
					</MenuItem>
				))}
			</TextField>

			{renderSocials()}

			<Button
				color='primary'
				onClick={handleSaveChanges}
				sx={{
					fontWeight: '600',
					letterSpacing: '0.05px',
					marginTop: '2rem',
					padding: 'auto 40px',
					width: '50%',
				}}
				variant='contained'
			>
				Save Changes
			</Button>
		</Stack>
	) : null;
}
