import { sanitizeString } from '@mpx-sdk/helpers/input';
import { newLibraryState } from '@mpx-sdk/helpers/library';
import { libraryURLParamsAtom, searchBarValue } from '@mpx-sdk/shared/atoms';
import SearchTextInput from '@mpx-sdk/ui/components/search-bar/SearchTextInput';
import SearchIconButton from '@mpx-sdk/ui/components/search-bar/buttons/SearchIconButton';
import { Grid, InputAdornment } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { ReactElement, Suspense, lazy, useState } from 'react';

const SearchSettings = lazy(() => import('@mpx-sdk/ui/components/search-bar/settings/SearchSettings'));

interface SearchBarProps {
	/** Whether the filter/settings should be displayed by default */
	hideDisplay?: boolean;
	/** Whether this is the search bar from the home or not */
	homeSearchBar?: boolean;
	/** Whether to display the search button [false, default] or not [true] */
	noSearchButton?: boolean;
	/** The function to call upon search action */
	onSearch?: (query: string) => void;
}

/**
 * Create a search bar with a search button
 * @returns {JSX} The search bar and search button
 * @example <caption>Call this component when you want to display a search bar on your app/page/component. You need to give a onSearch for it to do anything. If you do not want the search button to appear, also include noSearchButton={false}</caption>
 * <SearchBar onSearch={onSearch} noSearchButton={true} />
 * // returns {JSX component for search bar and search button}
 * @export
 */
export default function SearchBar({
	hideDisplay,
	homeSearchBar,
	noSearchButton,
	onSearch,
}: SearchBarProps): ReactElement {
	const [hideSearchSettings] = useState(hideDisplay ?? false);
	/** Current search query */
	const [searchQuery, setSearchQuery] = useAtom(searchBarValue);
	const setLibraryURLParams = useSetAtom(libraryURLParamsAtom);

	/** Handle when a key press happens */
	const handleKeyPress = (event, search = false) => {
		/** Key press type */
		const eventType = event?.key || event?.type;

		/** Current query if exists */
		let query = event?.target?.defaultValue || event?.target?.textContent || '';

		// Sanitize query
		query = sanitizeString(query) || '';

		// If press enter, do search function
		if (['enter'].includes(eventType.toLowerCase()) || search) {
			if (onSearch) {
				onSearch(query);
			} else {
				setLibraryURLParams(newLibraryState({ q: query.trim() }) || {});
			}
		} else if (!searchQuery || query.trim() !== searchQuery.trim()) {
			setSearchQuery(query);
		}
	};

	return (
		<Grid
			alignItems='center'
			container
			direction='row'
			justifyContent='space-evenly'
			sx={{
				cursor: 'pointer',
			}}
		>
			<Grid item xs={12}>
				<SearchTextInput
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{!noSearchButton && (
									<SearchIconButton
										onClick={() => {
											const inputQuery: string =
												(document.getElementById('search-bar') as HTMLInputElement)?.value ||
												'';

											handleKeyPress(
												{
													target: {
														defaultValue: inputQuery,
													},
													key: 'Enter',
												},
												true,
											);
										}}
									/>
								)}
							</InputAdornment>
						),
					}}
					onKeyDown={handleKeyPress}
				/>
			</Grid>

			{!homeSearchBar ? (
				<Grid item xs={12}>
					<Suspense fallback={null}>
						<SearchSettings display={!hideSearchSettings} />
					</Suspense>
				</Grid>
			) : null}
		</Grid>
	);
}
