import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useInstantSearch } from 'react-instantsearch';

interface NoResultsProps {
	query?: string;
}

export default function NoResults({ query }: NoResultsProps): ReactElement | null {
	const { indexUiState } = useInstantSearch();

	return (
		<Typography mt={3} textAlign='center' variant='h5'>
			No results for <q>{query ?? indexUiState.query}</q>.
		</Typography>
	);
}
