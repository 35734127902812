import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { Grid } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactElement, Suspense, lazy } from 'react';

const FilterCategories = lazy(() => import('@mpx-sdk/ui/components/search-bar/settings/FilterCategories'));
const ShowcaseTags = lazy(() => import('@mpx-sdk/ui/components/search-bar/settings/ShowcaseTags'));

interface SearchSettingsProps {
	display?: boolean;
}

export default function SearchSettings({ display }: SearchSettingsProps): ReactElement {
	const inApp = useAtomValue(inAppBrowserAtom);

	return (
		<Grid
			alignItems='flex-start'
			className={`search-filters ${!display ? 'search-filters-hidden' : ''} `}
			container
			direction='row'
			justifyContent='center'
			sx={{
				'.MuiCheckbox-colorPrimary': {
					svg: {
						color: 'primary.main',
					},
				},
			}}
		>
			{!inApp && (
				<Grid className='search-filter-options' item xs={12}>
					<Suspense fallback={null}>
						<FilterCategories />
					</Suspense>
				</Grid>
			)}

			<Suspense fallback={null}>
				<Grid className='search-filter-options' item xs={12}>
					<ShowcaseTags />
				</Grid>
			</Suspense>
		</Grid>
	);
}
